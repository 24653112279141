<template>
  <div class="introb1">
    <div class="introb1__wrapper data fullscreen">
      <div class="introb1__content">
        <h1 class="introb1__h1 h1">Datenschutzerklärung :</h1>
        <p class="p introb1__p">
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
          sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
          entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung. Die Nutzung unserer Webseite ist in der Regel
          ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten
          personenbezogene Daten (beispielsweise Name, Anschrift oder
          E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets
          auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
          Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin, dass
          die Datenübertragung im Internet (z.B. bei der Kommunikation per
          E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der
          Daten vor dem Zugriff durch Dritte ist nicht möglich. Cookies Die
          Internetseiten verwenden teilweise so genannte Cookies. Cookies
          richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.
          Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
          sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem
          Rechner abgelegt werden und die Ihr Browser speichert. Die meisten der
          von uns verwendeten Cookies sd so genannte „Session-Cookies“. Sie
          werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies
          bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Diese
          Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch
          wiederzuerkennen. Sie können Ihren Browser so einstellen, dass Sie
          über das Setzen von Cookies informiert werden und Cookies nur im
          Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder
          generell ausschließen sowie das automatische Löschen der Cookies beim
          Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies
          kann die Funktionalität dieser Website eingeschränkt sein.
          Server-Log-Files Der Provider der Seiten erhebt und speichert
          automatisch Informationen in so genannten Server-Log Files, die Ihr
          Browser automatisch an uns übermittelt. Dies sind: Browsertyp und
          Browserversion verwendetes Betriebssystem Referrer URL Hostname des
          zugreifenden Rechners Uhrzeit der Serveranfrage Diese Daten sind nicht
          bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit
          anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor,
          diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte
          für eine rechtswidrige Nutzung bekannt werden. Kontaktformular Wenn
          Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
          Angaben aus dem Anfrageformular inklusive der von Ihnen dort
          angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
          Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
          nicht ohne Ihre Einwilligung weiter. Einbindung von Diensten und
          Inhalten Dritter Es kann vorkommen, dass innerhalb dieses
          Onlineangebotes Inhalte Dritter, wie zum Beispiel Videos von YouTube,
          Kartenmaterial von Google-Maps, RSS-Feeds oder Grafiken von anderen
          Webseiten eingebunden werden. Dies setzt immer voraus, dass die
          Anbieter dieser Inhalte (nachfolgend bezeichnet als „Dritt-Anbieter“)
          die IP-Adresse der Nutzer wahr nehmen. Denn ohne die IP-Adresse,
          könnten sie die Inhalte nicht an den Browser des jeweiligen Nutzers
          senden. Die IP-Adresse ist damit für die Darstellung dieser Inhalte
          erforderlich. Wir bemühen uns nur solche Inhalte zu verwenden, deren
          jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der
          Inhalte verwenden. Jedoch haben wir keinen Einfluss darauf, falls die
          Dritt-Anbieter die IP-Adresse z.B. für statistische Zwecke speichern.
          Soweit dies uns bekannt ist, klären wir die Nutzer darüber auf.
          SSL-Verschlüsselung Diese Seite nutzt aus Gründen der Sicherheit und
          zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel der
          Anfragen, die Sie an uns als Seitenbetreiber senden, eine
          SSL-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
          daran, dass die Adresszeile des Browsers von „http://“ auf „https://“
          wechselt und an dem Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL
          Verschlüsselung aktiviert ist, können die Daten, die Sie an uns
          übermitteln, nicht von Dritten mitgelesen werden. Recht auf Auskunft,
          Löschung, Sperrung Sie haben jederzeit das Recht auf unentgeltliche
          Auskunft über Ihre gespeicherten personenbezogenen Daten, deren
          Herkunft und Empfänger und den Zweck der Datenverarbeitung sowie ein
          Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu
          sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie
          sich jederzeit unter der im Impressum angegebenen Adresse an uns
          wenden. Widerspruch Werbe-Mails Der Nutzung von im Rahmen der
          Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von
          nicht ausdrücklich angeforderter Werbung und Informationsmaterialien
          wird hiermit widersprochen. Die Betreiber der Seiten behalten sich
          ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung
          von Werbeinformationen, etwa durch Spam-E-Mails, vor. Google Web Fonts
          (*) Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten
          so genannte Web Fonts, die von Google bereitgestellt werden. Beim
          Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren
          Browsercache, um Texte und Schriftarten korrekt anzuzeigen. Zu diesem
          Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern
          von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber, dass
          über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von
          Google Web Fonts erfolgt im Interesse einer einheitlichen und
          ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein
          berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
          Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine
          Standardschrift von Ihrem Computer genutzt. Weitere Informationen zu
          Google Web Fonts finden Sie unter
          https://developers.google.com/fonts/faq und in der
          Datenschutzerklärung von Google:
          https://www.google.com/policies/privacy/. Google Maps (*) Diese Seite
          nutzt über eine API den Kartendienst Google Maps. Anbieter ist die
          Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
          Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP
          Adresse zu speichern. Diese Informationen werden in der Regel an einen
          Server von Google in den USA übertragen und dort gespeichert. Der
          Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.
          Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden
          Darstellung unserer Online-Angebote und an einer leichten
          Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies
          stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f
          DSGVO dar. Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in
          der Datenschutzerklärung von Google:
          https://www.google.de/intl/de/policies/privacy/ Wenn Sie nicht
          wünschen, dass Google Informationen über Ihre Nutzung von Google Maps
          erfassen kann, können Sie die Erfassung derartiger Informationen
          unterbinden, indem Sie den Google-Maps-Dienst vollständig
          deaktivieren. Dazu müssen Sie in Ihrem Browser JavaScript
          deaktivieren. Bitte beachten Sie, dass Sie in diesem Fall Google Maps
          nicht nutzen können. Quelle bis auf (*): eRecht24
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss"></style>
